<template>
  <v-data-table
    :headers="headers"
    :items="texts"
    item-key="name"
    class="elevation-1 row-pointer"
    @click:row="handleClick"
  >
  </v-data-table>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
export default {
  async created() {
    await this.loadTexts();
  },
  data() {
    return {
      singleSelect: false,
      selected: null,
      headers: [
        {
          text: "Logiciel",
          align: "start",
          sortable: false,
          value: "software",
        },
        { text: "Module", value: "module", sortable: false },
        { text: "Version", value: "version", sortable: false },
        { text: "Priorité", value: "priority", sortable: false },
        {
          text: "Langue originale",
          value: "originalLanguage",
          sortable: false,
        },
        { text: "Nombre de mot", value: "numberOfWords", sortable: false },
        { text: "Ajouté par", value: "username", sortable: false },
      ],
      texts: [],
    };
  },
  methods: {
    handleClick(a) {
      this.$router.push({
        name: "text-translations",
        query: { textGuid: a.guid },
      });
    },
    async loadTexts() {
      const response = await ApiService.get("v1/Texts");
      if (response.status == 200 && response.data != null) {
        this.texts = response.data;
      }
    },
  },
};
</script>