<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPanel title="Listing des texts"> <ListForme /> </KTPanel>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListForme from "./ListForme";

export default {
  components: {
    ListForme,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Texts", route: "list" },
      { title: "Listing" },
    ]);
  },
  methods: {},
};
</script>
